import {Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@mui/material";
import React from "react";

export const InfoTable = ({title, headerTitles, products}) => {
    if (products.length === 0) return null;

    return (
        <Grid item container>
            <Typography variant="subtitle1" mr={3}>{title}</Typography>
            <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {
                                headerTitles.map((column, index) => (
                                    <TableCell
                                        key={index}
                                    >
                                        {column}
                                    </TableCell>
                                ))
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            products.map((el, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>{el.name}</TableCell>
                                        <TableCell>{el.code}</TableCell>
                                        <TableCell>{el.size}</TableCell>
                                        <TableCell>{el.amount}</TableCell>
                                    </TableRow>
                                )
                            )
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
    )
}